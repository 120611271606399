import React, { Component } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

class FFTSignal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: 'Gravity',
        data: []
      },
      {
        name: 'mm/s',
        data: []
      }
    ],
      options: {
        chart: {
          id: "mychart",
          type: 'area',
          stacked: false,
          height: 100,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          },
          animations: {
            enabled: false
          },
          events: {
            markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
              // alert(chartContext.data.twoDSeriesX[dataPointIndex])
              // props.onReceiveAnnotation(chartContext.data.twoDSeriesX[dataPointIndex])
            }
          }
        },
        noData: {
          text: 'No data available !',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: 'black',
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'FFT Spectrum',
          align: 'left'
          
        },
        // yaxis: {
        //   labels: {
        //     formatter: function (val) {
        //       return Math.round(val* 10000)/ 10000
        //     },
        //   },
        //   title: {
        //     text: 'Gravity'
        //   },
        // },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#4287f5'
            },
            title: {
              text: "Gravity",
              style: {
                color: '#4287f5',
              }
            },
            tooltip: {
              enabled: true,
              show: false,
            },
            labels: {
              style: {
                colors: '#4287f5',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#38ff74'
            },
            title: {
              text: "mm/s",
              style: {
                color: '#38ff74',
              }
            },
            labels: {
              style: {
                colors: '#38ff74',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          }
        ],
        xaxis: {
          type: 'numeric',
          categories: [],
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          ,title: {
            text: 'Time'
          } 
          }
        },
        stroke: {
            width: [1, 1],
        },
        tooltip: {
          shared: false,
          x: {
            show: false,
            formatter: function (val, series) {
              // `Amplitude: `+series.series[0][series.dataPointIndex].toFixed(4)
              return `Hz: ` +val.toFixed(4)
            }
          }
        }
      },
      toggleDisable: false
    };
    this.initialState = { ...this.state }
  }

  getTodos() {
    // SET GRAVITY AXIS
    this.setState(prevState => ({
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: this.props.fftFreq
        }
      },
      series: prevState.series.map(
        el => {
          if (el.name == 'Gravity') return { ...el, data: this.props.fftMag }
          if (el.name == 'mm/s') return { ...el, data: this.props.fftFreqMMS }
        }
      ),
    }));
  }

  addMMSUnit = () => {
    ApexCharts.exec("mychart", "appendSeries", {
      name: '0 - 1 Hz',
      data: this.props._show0to1Hz
    });
    this.setState({toggleDisable: true})
  };

  componentDidMount() { this.setState(this.initialState)
    // console.log(this.props.fftMag)
    // console.log(this.props.fftFreqMMS)

  }
  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) this.setState(this.initialState);
    if (this.props.fftFreq !== prevProps.fftFreq) {
      this.setState(this.initialState);
      this.getTodos();
    }
    if (this.props.annotation !== prevProps.annotation) {
      this.setAnnotation(this.props.annotation)
    }
  }
  

  render() {
    return (
      <>
        <Chart options={this.state.options}
          series={this.state.series}
          type="line"
          width="95%"
          height="85%" />
          <div>
        <button className="btn-add-mms" onClick={this.addMMSUnit} disabled={this.state.toggleDisable}>Show 0-1Hz values</button>
      </div>

      </>
    );
  }
}

export default FFTSignal;