import constants from '../../src/constants.json'
import axios from 'axios'
export default function APIChangePwd(oldPwd, newPwd) {
    var token = localStorage.getItem('token')
    var data = {
        "old": oldPwd,
        "new": newPwd
    }

    var config = {
        method: 'patch', 
        headers: {
          'Authorization': `token ${token}`
        },
        url: constants.baseAddress + 'user',
        data : data
      };
      
    return axios(config)
      .then(response => response)
      .catch(error => 
        error.response.status
    );
}