import React from 'react'
import './graph.css'
import {Redirect } from "react-router-dom";
import Charts from './Charts'
import Trend from './Trend'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import APIRefreshToken from '../../../api/APIRefreshToken'

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          updateTS: false
        }
    }

    getNewToken = () => {
        var rToken = localStorage.getItem('refreshToken');
        APIRefreshToken(rToken)
        .then((results) => {
            if(results === 401 || results === 403) {
                this.props.onLogOut()
                this.props.history.push(this.props.redirectPathOnSuccess)
                window.location.reload(false);
            } else {
                console.log(results)
                localStorage.setItem('token',results.token)
            }
        })
        .catch(error => console.error(error))
    }

    
    componentDidMount() {
        this.getNewToken()
        const interval = setInterval(() => {
            var token = localStorage.getItem('token');
            if(token !== null) {
                this.getNewToken()
                console.log('This will run every 30 seconds!');
            }
        }, 30000);
        return () => clearInterval(interval);
    }

    onUpdateTS = () => { this.setState({ updateTS: !this.state.updateTS}) }

    render() {
        const token = localStorage.getItem('token')
        const permissionLevel = localStorage.getItem('permissionLevel');
        const database = localStorage.getItem('database');

        const app = () => {
            return <div className="container-fluid" style={this.props.isDisplay ? {marginLeft:"220px", width:"88%"} : {}}>
                    <BlockUi tag="div" blocking={this.props.blocking} message="Fetching data....">
                    <Trend isDisplay={this.props.isDisplay} device={this.props.device} updateTS = {this.state.updateTS} />
                    <Charts isDisplay={this.props.isDisplay} device={this.props.device}
                            updateTS = {this.state.updateTS} onUpdateTS = {this.onUpdateTS} toggleBlocking={this.props.toggleBlocking} 
                    />
                    </BlockUi>
                    
                </div>
        }

        if(token) {
            if(permissionLevel === 'user')
                return ( <>  {app()}  </> )
            else {
                if(database) return ( <>  {app()}  </> )
                else return (  <>  <Redirect to="databases"/>  </> ) 
            }
        } else return (  <>  <Redirect to="/"/>  </>  ) 
    }
    
}
