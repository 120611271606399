import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// notification style
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function CodeRegister(props) {
  const classes = useStyles();
  toast.configure();

  const [resendCode, setResendCode] = useState("Didn't get a code? Resend");
  const [code, setCode] = useState("");

  function onResendCode() {
    toast('Code sent')
    setResendCode('Code sent')
  }

  function onhandleCode(event) {
    event.preventDefault();
    if (code.length < 6) {
      toast.warning('Invalid code!')
    }else {
        props.history.push(props.redirectPathOnSuccess)
    } 
  }
  var token = localStorage.getItem('token');
  if (!token) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FingerprintIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
              Enter Security Code
          </Typography>
          <Typography variant="caption">Please check your email for a message with your code. Your code is 6 numbers long.
          </Typography>
          <form className={classes.form} onSubmit={onhandleCode}>
            <TextField  variant="outlined"  margin="normal"  required   fullWidth name="code"  label="Enter code"  autoComplete="current-password"
              inputProps={{
                maxLength: 6
              }}
              value={code}
              onChange={(e) => {setCode(e.target.value)}}
            />
            <Button  type="submit"  fullWidth variant="contained"  color="primary"  className={classes.submit} >
              Continue
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to ="#" variant="body2" style={{ textDecoration: 'none' }} onClick={onResendCode}>
                  {resendCode}
                </Link>
              </Grid>
              <Grid item>
                <Link to ="/register" variant="body2" style={{ textDecoration: 'none' }}>
                  {'Change email address'}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  } else {
    return(
      <Redirect to="/devices"/>
    );
  }
}