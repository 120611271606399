import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import APISendEmail from '../../../api/auth/APISendEmail'
// notification style
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function Recover(props) {
  const classes = useStyles();
  toast.configure();

  const [email, setEmail] = useState("");
  const [repeatEmail, setRepeatEmail] = useState("");

  function recover(event)
  {
    event.preventDefault();

    if(email === repeatEmail) {
      APISendEmail(email)
      .then(results => {
        console.log(results)
        toast.success('An email sent to your private mail!', { autoClose: 1600 })
        props.history.push(props.redirectPathOnSuccess)
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      toast.error('Email has to be the same !', { autoClose: 1600 })
    }
  }

  var token = localStorage.getItem('token');
  if (!token) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
         Password Recovery
        </Typography>
        <Typography variant="caption">Please enter your email address twice to recover your password. An email containing further instructions will be sent to the address provided.</Typography>
        <form className={classes.form} onSubmit={recover}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => {setEmail(e.target.value)}}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="Repeat email"
            id="email"
            autoComplete="email"
            value={repeatEmail}
            onChange={(e) => {setRepeatEmail(e.target.value)}}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={false}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Link to ="/" variant="body2" style={{ textDecoration: 'none' }}>
                {"Cancel password recovery"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
  } else {
    return(
      <Redirect to="/devices"/>
    );
  }
}