import React, { Component } from 'react'
import SyncIcon from '@material-ui/icons/Sync';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Battery60Icon from '@material-ui/icons/Battery60';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import TimelineIcon from '@material-ui/icons/Timeline';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import VRMS from './singlechart/VRMS'
import SignalGpeak from './singlechart/SignalGpeak';
import Envelope from './singlechart/Envelope';
import Gpeak from './singlechart/Gpeak';
import Battery from './singlechart/Battery';
import Temperature from './singlechart/Temperature';
import SetLimitBtn from './SetLimitBtn'

export default class Trendingbtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }


  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        <div style={{ float: 'right' }}>
          <Tooltip title="Update devices">
            <IconButton onClick={() => { this.props.onUpdateTS() }}>
              <SyncIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Drag">
            <IconButton onClick={() => { this.props.onDrag() }}>
              <ZoomOutMapIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
          {/* FIRST POPUP */}
          <Popup trigger={
            <Tooltip title={this.props.name[0]}>
              <IconButton aria-owns={open ? 'menu-appbar' : null} aria-haspopup="true">
                {this.props.name[0] === 'VRMS' ? <div style={{ fontSize: 16, color: 'black'  }}>V</div> : <TimelineIcon style={{ fontSize: 16, color: 'blue' }}/> }
              </IconButton>
            </Tooltip>} modal>
            {close => (
              <div>
                {this.props.name[0] === 'VRMS' ? 
                  <VRMS port={this.props.port} id={this.props.id}/> : 
                  <Gpeak port={this.props.port} id={this.props.id}/> }
                <button className="close" onClick={close}>
                  &times;
                </button>
              </div>
            )}
          </Popup>
            {/* SECOND POPUP */}
          <Popup trigger={
            <Tooltip title={this.props.name[1]}>
              <IconButton aria-owns={open ? 'menu-appbar' : null} aria-haspopup="true">
              {this.props.name[1] === 'Battery' ? <Battery60Icon style={{ fontSize: 16, color: 'green' }} /> : <div style={{ fontSize: 16, color: 'blue'  }}>P</div> }
              </IconButton>
            </Tooltip>} modal>
            {close => (
              <div>
                {this.props.name[1] === 'Battery' ? 
                  <Battery port={this.props.port} id={this.props.id}/> : 
                  <SignalGpeak port={this.props.port} id={this.props.id}/> }
                <button className="close" onClick={close}>
                  &times;
                </button>
              </div>
            )}
          </Popup>
            {/* THIRD POPUP */}
          <Popup trigger={
            <Tooltip title={this.props.name[2]}>
              <IconButton aria-owns={open ? 'menu-appbar' : null} aria-haspopup="true">
              {this.props.name[2] === 'Temperature' ? <AcUnitIcon style={{ fontSize: 16, color: 'ffa203' }} /> : <div style={{ fontSize: 16, color: '#730099' }}>E</div> }
              </IconButton>
            </Tooltip>} modal>
            {close => (
              <div>
                {this.props.name[2] === 'Temperature' ? 
                  <Temperature port={this.props.port} id={this.props.id}/> : 
                  <Envelope port={this.props.port} id={this.props.id}/> }
                <button className="close" onClick={close}>
                  &times;
                </button>
              </div>
            )}
          </Popup>
          <SetLimitBtn id={this.props.id} port={this.props.port} onUpdate={this.props.onUpdateTS}/>
        </div>
      </>
    )
  }
}
