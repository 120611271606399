import constants from '../constants.json'
import axios from 'axios'
export default function APIGetLimit(device,token) {
  
  var data = {}
  
  var userObj = {
    name: device
  };

  const databaseId  = localStorage.getItem('database')
  const permissionLevel = localStorage.getItem('permissionLevel')
  var adminObj = {
    name: device,
    "targetId": databaseId
  }

  data = permissionLevel === 'admin' ? adminObj : userObj

  var config = {
    method: 'get', 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'locations/getlimits',
    params: data
  };
  
  return axios(config)
  .then(response => {
    return response.data
  })
  .catch(function(error) {
    if (!error.status) {
      console.log('get limit error')
    }});
}

