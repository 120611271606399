import React from 'react';
import APIGetLocations from '../../../api/APIGetLocations'
// import APIGetTree from '../../../api/APIGetTree'
import APIRefreshToken from '../../../api/APIRefreshToken';
import './devices.css'
import sortBy from 'lodash/sortBy';
import SearchIcon from '@material-ui/icons/Search';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from '@material-ui/icons/Sync';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loader from 'react-loader-spinner';
import TreeFolder from './TreeFolder'

export default class Locations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [], //for sort
            search: "",
            openSearch: false,
            device: null,
            loading: true,//for spinner
            token: localStorage.getItem('token')
        }
        this.initialState = { ...this.state }
    }

    componentDidMount() {
        this.toDos(this.state.token)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.status !== prevProps.status) {
            var token = localStorage.getItem('token')
            this.setState(this.initialState)
            this.toDos(token)
        }
    }

    toDos(token) {
        if (token) {
            const refreshToken = localStorage.getItem('refreshToken')
            APIRefreshToken(refreshToken)
            .then((results) => {
                    if(results === 401 || results === 403) {
                        alert('Please login again !')
                        this.props.onLogOut()
                        this.props.history.push(this.props.redirectPathOnSuccess)
                        window.location.reload(false);
                    } else {
                        this.setState({token: results.token})
                        
                        APIGetLocations(this.state.token)
                        .then((locations) => {
                            console.log(locations)
                            if (locations === undefined) {
                                console.log('no data')
                            } else {
                                // demo for port 50004 -> remove :Montarget: devices
                                console.log('loaded devices !')
                                var newData = []
                                newData = locations.data.filter(l => !(l.includes("MonTarget")))
                                this.setState({ devices: newData, loading: false });
                            }
                        })
                        .catch(console.log('get devices error'))
                    }
            })
        }
    }


    onOpenSearch() { this.setState({ openSearch: !this.state.openSearch }) }

    onSort() {
        var sortedDevices = this.state.devices;
        sortedDevices = sortBy(sortedDevices); // Use Lodash to sort array by 'name'
        this.setState({ devices: sortedDevices })
    }

    onUpdate() {
        this.toDos(this.state.token);
        toast.success('Updated devices', { autoClose: 1600 })
    }

    updateSearch(event) { this.setState({ search: event.target.value.substr(0, 20) }) }

    render() {

        toast.configure();
        if (this.state.loading) {
            const token = localStorage.getItem('token')
            if(token) return (<Loader type="TailSpin" color="#00BFFF" timeout={4000} height={80} width={80} />)
            else return null;
        }
        else {
                return (
                    <>  <div style={{ marginLeft: '5%' }}>
                        <Tooltip title="Sort by alphabet order">
                            <IconButton onClick={() => { this.onSort() }}>
                                <SwapVertIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Search for a device">
                            <IconButton onClick={() => { this.onOpenSearch() }}>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Update devices">
                            <IconButton onClick={() => { this.onUpdate() }}>
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                        <input type="text" className={this.state.openSearch ? 'form-control searchInput' : 'hidden'} placeholder="Device name"
                            value={this.state.search} onChange={this.updateSearch.bind(this)}
                        ></input>

                        <TreeFolder devices={this.state.devices} setDevice={this.props.setDevice} search={this.state.search} />
                    </>
                )
        }
    }
}