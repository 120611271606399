import constants from '../../constants.json'
import axios from 'axios'
export default function APISendTestMsg(id) {
  
  const token  = localStorage.getItem('token')

  var config = {
    method: 'post', 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'alarm/'+id,
  };
  
  return axios(config)
  .then((res) => res)
  .catch((error) => {
    console.error(error)
  })
}

