var fft = require('fft-js').fft
var fftUtil = require('fft-js').util

export default function generatefft(data, rate) {
    var newData = [...data]
    if (data.length > 32768) {    // 2^15 = 32769
        // cut the array to 32768 points
        newData = newData.slice(0,32768)
    } 
    else {
        var zero = 32768 - newData.length;
        for (let i = 0; i < zero; i ++) {newData.push(0)}
    }
    var phasors = fft(newData);
    var frequencies = fftUtil.fftFreq(phasors, rate), // Sample rate and coef is just used for length, and frequency step
    magnitudesTemp = fftUtil.fftMag(phasors);
    var magnitudes = magnitudesTemp.map(m => {
        return m/rate;
    })
    var stepPoint = frequencies[1]
    var index = (1000/stepPoint).toFixed(0)
    return { frequency: frequencies, magnitude: magnitudes, index: index*1 }; //convert index to number type
}
