import constants from '../constants.json'
import axios from 'axios'

export default function APISetLimit(signal, warning, alarm, token) {
    let object = {
        _name: signal,
        _limit: [
            {
                _level: 1,
                _limit_low: "-Infinity",
                _limit_high: warning
            },
            {
                _level: 3,
                _limit_low: "-Infinity",
                _limit_high: alarm
            }
        ]
    }

    var config = {
        method: 'post', 
        headers: {
          'Authorization': `token ${token}`
        },
        url: constants.baseAddress + 'locations/setlimits',
        data: object
      };

    return axios(config)
        .then(response => response)
        .catch(error => error);
}