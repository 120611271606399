import constants from '../constants.json'
import axios from 'axios'

export default function _AdminGetTargets() {
    const token = localStorage.getItem('token')
    return axios.get(constants.baseAddress + 'target', {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            console.error(error)
        })
}
