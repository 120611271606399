import constants from '../constants.json'
import axios from 'axios'
export default function APIGetTimeSignal(device, timestamp, token) {

  var data = {}
  
  var userObj = {
    "name" : device,
    "stamp" : timestamp
  };

  const databaseId  = localStorage.getItem('database')
  const permissionLevel = localStorage.getItem('permissionLevel')
  var adminObj = {
    "name" : device,
    "stamp" : timestamp,
    "targetId": databaseId
  }

  data = permissionLevel === 'admin' ? adminObj : userObj

  
  var config = {
    method: 'get', 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'locations/signal',
    params: data
  };
  
  return axios(config)
  .then(response => response.data)
  .catch(function(error) {
    if (!error.status) {
      console.log('time signal error')
    }});
}