import React, { useState } from 'react'
import {Switch, Route} from 'react-router-dom'
import Main from './graphs/Main'
import Login from './auth/Login'
import Recover from './auth/Recover'
import Register from './auth/Register'
import CodeRegister from './auth/CodeRegister'
import Password from './auth/Password'
import Reset from './auth/Reset'
import NotFound from './NotFound'
import RegisterAuth from './auth/RegisterAuth'
import SelectDatabase from '../admin/SelectDatabase'
import Account from './account/Account'


export default function Pages(props) {
    const [emailRegister, setEmailRegister] = useState('');
        return (
            <>  
                <Switch>
                <Route path="/" exact render={(routeProps) => <Login onLogin = {props.onLogin} {...routeProps} onSetPort={props.onSetPort} redirectPathOnSuccess="/devices"/>}/>
                <Route path="/devices" exact render={(routeProps) => <Main device={props.device} status={props.status} isDisplay = {props.isDisplay} redirectPathOnSuccess="/"
                port={props.port} blocking={props.blocking} toggleBlocking={props.toggleBlocking} onLogOut = {props.onLogOut}
                {...routeProps} />}/>
                <Route path="/databases" exact render={(routeProps) => <SelectDatabase {...routeProps} isDisplay={props.isDisplay}/>}/>
                <Route path="/recover" exact render={(routeProps) => <Recover {...routeProps} 
                                                                         redirectPathOnSuccess="/"
                />}/>
                <Route path="/password" exact render={(routeProps) => <Password {...routeProps} 
                                                                                redirectPathOnSuccess="/"
                />}/>
                <Route path="/reset/:id" exact render={(routeProps) => <Reset {...routeProps} 
                                                                                redirectPathOnSuccess="/"
                />}/>
                <Route path="/code" exact render={(routeProps) => <CodeRegister {...routeProps} 
                                                                                redirectPathOnSuccess="/password"
                />}/>
                <Route path="/register" exact render={(routeProps) => <Register {...routeProps}
                                                                                redirectPathOnSuccess="/identify" setEmailRegister = {setEmailRegister}
                />}/>
                <Route path="/identify" exact render={(routeProps) => <RegisterAuth {...routeProps} emailRegister= {emailRegister}
                                                                                redirectPathOnSuccess="/"
                />}/>
                <Route path="/account" exact render={(routeProps) => <Account {...routeProps} isDisplay = {props.isDisplay} 
                                                                                redirectPathOnSuccess="/"
                />}/>
                <Route component={NotFound} />
                </Switch>
            </>
        )
}
