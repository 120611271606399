import constants from '../../constants.json'
import axios from 'axios'

export default function APIGetTelegramAlarms() {

  const token = localStorage.getItem('token')
  
  var config = {
    method: 'get', 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'alarm',
  };

  return axios(config)
  .then((res) => res)
  .catch((error) => {
    console.error(error)
  })
}

