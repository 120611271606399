import React, { Component } from "react";
import Chart from "react-apexcharts";
import APIGetTimeSignal from '../../../api/APIGetTimeSignal'
import options from './config/timeSignalOption'
import fft from './function/generatefft'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class TimeSignal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: 'Time signal',
        data: []
      }],
      options
    };
    this.initialState = { ...this.state }
  }

  // componentDidMount() { this.getTodos() }

  componentDidUpdate(prevProps) {
    if (this.props.timestamp !== prevProps.timestamp ) {
      this.setState(this.initialState);
      this.getTodos();
    }
    else if (this.props.id !== prevProps.id) {
      this.setState(this.initialState);
    }
  }

  getTodos() {
    const device = this.props.id;
    if( this.props.timestamp === 0) return null 
    else {
      const token = localStorage.getItem('token')
      APIGetTimeSignal(device, this.props.timestamp, token)
      .then(results => {
        // send fftValue and rate to parent cpn
        const fftValue = fft(results._values, results._rate);
        this.props.onReceiveFFT(fftValue)
        this.props.onReceiveRate(results._rate)

        // get rate => set to time stamp (1/._rate)
        var valueY = 0
        // find array length, init a timestamp array corresponding to array value
        var timeStamp = []
        // loop to push data
        if (results !== undefined) {
          for (let i = 0; i < results._values.length; i++) {
            timeStamp.push(valueY);
            valueY += (1 / results._rate)
          }
          this.setState(prevState => ({
            options: {
              ...this.state.options,
              xaxis: {
                ...this.state.options.xaxis,
                categories: timeStamp
              },
              annotations: {
                ...this.state.animations,
                points: prevState.options.annotations.points.map(
                  el => el.hi === 'test' ? { ...el, x: timeStamp.slice(-1)[0], y: results._values.slice(-1)[0] } : el
                )
              }
            },
            series: prevState.series.map(
              el => el.name === 'Time signal' ? { ...el, data: results._values } : el
            ),
          }));
        } else return null
        toast.success('Data updated', { autoClose: 1600 })
      })
      .catch(
        () => console.log('update ts data fail/ no data')
      )
    }
  }

  render() {
    toast.configure();
    return (
      <>
        <Chart options={this.state.options}
          series={this.state.series}
          type="line"
          width="95%"
          height="85%" />
      </>
    );
  }
}

export default TimeSignal;