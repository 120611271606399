import React, { Component } from "react";
import Chart from "react-apexcharts";
import APIGetData from '../../../api/APIGetData'

class Signal3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: 'VRMS',
        type: 'line',
        data: []
      }, {
        name: 'Signal Gpeak',
        type: 'line',
        data: []
      }, {
        name: 'Envelope',
        type: 'line',
        data: []
      },],
      options: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          events: {
            markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
              console.log(event.altKey, event.ctrlKey, event.shiftKey)
              if(event.ctrlKey) props.setTimeStamp(chartContext.data.twoDSeriesX[dataPointIndex])
              else console.log(chartContext.data.w.config.series[1].data[dataPointIndex])
            }
          }
        },
        colors: ['#000000', '#0066ff', '#730099'],
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          style: {
            fontSize: '14px',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [2, 2, 2],
        },
        title: {
          text: 'Trends',
          align: 'left',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
            , title: {
              text: 'Time'
            }
          }
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#000000'
            },
            title: {
              text: "VRMS",
              style: {
                color: '#000000',
              }
            },
            tooltip: {
              enabled: true,
              show: false,
            },
            labels: {
              style: {
                colors: '#000000',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#0066ff'
            },
            title: {
              text: "signal gpeak",
              style: {
                color: '#0066ff',
              }
            },
            labels: {
              style: {
                colors: '#0066ff',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
          {
            seriesName: 'Envelope',
            opposite: true,
            axisBorder: {
              show: true,
              color: '#730099',
            },
            title: {
              text: "envelope",
              style: {
                color: '#730099',
              }
            },
            labels: {
              style: {
                colors: '#730099',
              },
              formatter: function (val) {
                return Math.round(val * 100) / 100;
              },
            }
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          },
          style: {
            fontSize: '11px',
            fontFamily: undefined
          },
          y: {
            formatter: function (val) {
              return Math.round(val * 10000) / 10000;
            }
          },
          x: {
            show: false,
            formatter: function (val) {
              // console.log(val)
              var date = new Date(val);
              var convertedDate = date.toLocaleString('en-GB', { hour12: false })
              return `Time : ` + convertedDate;

            }
          },
          custom: function() {
            return '<p>' +
            '<b>Use Ctrl + Click to select the point and view</b>'+
            '</p>';
            }
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        },
      }
    }
    this.initialState = { ...this.state }
  }
  async getTodos() {
    const device = this.props.id;
    var token = localStorage.getItem('token')
    APIGetData(device, token)
      .then((results) => {
        // if there is an error
        if (device === null) {
          console.log('please select ID')
        }
        else if (results === undefined) {
          console.log('no data found')
        } else {
          
          this.setState(this.initialState);
          // THIS METHOD IS TO FIND Y-AXIS MAX VALUE ARRAY, THEN SET IT TO THE CHART
          let envStamp,spStamp, vrmsStamp;
          // GET THE VALUES
          results.forEach((r) => {
            if(r._type === 'vrms') vrmsStamp = r._stamps;
            if(r._type === 'signal gpeak') spStamp = r._stamps;
            if(r._type === 'envelope') envStamp = r._stamps;
          })
          var maxStampLength, newStamp;
          // FIND THE MAX LENGTH
          if(envStamp !== undefined && spStamp !== undefined && vrmsStamp !== undefined) maxStampLength = Math.max(envStamp.length,spStamp.length, vrmsStamp.length)
          else {
            var testStamp = [envStamp,spStamp, vrmsStamp]
            testStamp = testStamp.filter(function( element ) {
              return element !== undefined;
            });
            var index = testStamp.reduce((p, c, i, a) => a[p].length > c.length ? a : i, 0);
            if(testStamp.length > 0) maxStampLength = testStamp[index].length
          }
          
          results.forEach((r) => {
            //  LOOKING FOR THE MAX LENGTH STAMP THAT CORRESPONDING TO THE MAX VALUE FOUNDED BEFORE
             if(r._stamps.length === maxStampLength) {
              newStamp = r._stamps
             }
          })

          results.map((r, i) => {
            r._magnitudes = r._magnitudes.filter(m => m != "NaN")
            r._stamps.splice(i, 1)
          })

          results.forEach((r) => {
            if (r._type === 'envelope') {
              // set lastest timestamp for time signal
              this.props.setTimeStamp(r._stamps.slice(-1)[0])
              
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Envelope' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
            if (r._type === 'signal gpeak') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'Signal Gpeak' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }

            if (r._type === 'vrms') {
              this.setState(prevState => ({
                options: {
                  ...this.state.options,
                  xaxis: {
                    ...this.state.options.xaxis,
                    categories: newStamp
                  },
                },
                series: prevState.series.map(
                  el => el.name === 'VRMS' ? { ...el, data: r._magnitudes } : el
                ),
              }));
            }
          })
        }
      })
      .catch(console.log('400'))
  }

  componentDidMount() { this.getTodos() }

  componentDidUpdate(prevProps) { 
    if (this.props.id !== prevProps.id || this.props.update !== prevProps.update || this.props.onDrag !== prevProps.onDrag) { this.getTodos() } 
  }

  render() {
    return ( <> <Chart options={this.state.options} series={this.state.series}
                        type="line" width="100%" height="84%" />
             </>);
  }
}
export default Signal3;