import React, { useEffect } from 'react'
import { Tooltip, IconButton } from "@material-ui/core";
import { Add } from '@material-ui/icons';

import styles from "./TelegramSettings.module.css";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import APIGetTelegramAlarms from '../../../api/telegram/APIGetTelegramAlarms';
import APIAddTelegramAlarm from '../../../api/telegram/APIAddTelegramAlarm';
import APIDeleteTelegramAlarm from '../../../api/telegram/APIDeleteTelegramAlarm'
import APIEditTelegramAlarm from '../../../api/telegram/APIEditTelegramAlarm';

import TelegramItems from './TelegramItems'

export default function Telegramsettings(props) {
    toast.configure();

    const [selectedOption, setSelectedOption] = React.useState("Private Message")
    const [items, setItems] = React.useState([])

    function onValueChange(event) {
        setSelectedOption(event.target.value)
    }

    var checkType = () => {
        if (selectedOption === "Private Message") return "telegram"
        else if (selectedOption === "Group Message") return "telegramgroup"
        else return "email"
    }

    function refreshNewItem() {
        console.log('REFRESH NEW ITEM CALLED')
        APIGetTelegramAlarms()
            .then(res => {
                console.log(res.data)
                setItems(res.data)
            })
    }

    // THIS FUNCTION HELP TO SYNC DATA FIRST RENDER
    useEffect(() => {
        refreshNewItem()
    }, [])

    function addTelegram(event) {
        event.preventDefault();
        var groupName = event.target['groupName'].value
        var groupMsgId = event.target['groupMsgId'].value
        var selectedType = checkType()

        // VALIDATION FE SIDE
        APIGetTelegramAlarms()
            .then(res => {
                var alarm = res.data //type Array

                if (alarm.length === 0) {
                    APIAddTelegramAlarm(groupName, selectedType, groupMsgId)
                    refreshNewItem()
                    toast.success('Alarm add successfully !')

                }
                else if (alarm.length === 2) toast.warning('Alarms are already declared. Please delete the previous one to continue!')
                else {
                    // check if there is any alarm, then stop adding it
                    alarm.map(a => {
                        //"types":["email","telegram","telegramgroup"]
                        if (a.type === selectedType) {
                            toast.warning(`${selectedType} is already declared. Please delete the previous one to continue!`)
                        }
                        else {
                            APIAddTelegramAlarm(groupName, selectedType, groupMsgId)
                            refreshNewItem()
                            toast.success('Alarm add successfully !')
                        }
                    })
                }
            })
            .catch(err => console.log(err))
    }

    function deleteItem(id) {
        if (window.confirm('Are you sure you want to delete this alarm?')) {
            APIDeleteTelegramAlarm(id)
                .then(res => {
                    if (res.status === 200) {
                        refreshNewItem()
                        toast.success('Removed alarm !')
                    } else {
                        toast.warn('Some errors happened !')
                    }
                })
        } else {
            console.log('NO DELETE ALARM');
        }
    }

    function editItem(event) {
        event.preventDefault();
        var id = event.target['id'].value
        var name = event.target['groupName'].value
        var uri = event.target['groupMsgId'].value

        APIEditTelegramAlarm(id, name, uri)
            .then(res => {
                if (res.status === 200) {
                    refreshNewItem()
                    toast.success('Edited alarm !')
                } else {
                    toast.warn('Some errors happened !')
                }
            })
            .catch(error => console.log(error))
    }

    const name = selectedOption === "Private Message" ? "private" : ""

    return (
        <>
            <div className="tab-content p-md-5" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                    <div id={styles.containerIntro}>
                        <h3>Telegram Alarm</h3>
                        &nbsp;
                        <Popup trigger={
                            <Tooltip title="Add a telegram alarm">
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        } modal>
                            {close => (
                                <div className={styles.popupModal}>
                                    &nbsp;
                                    <form onSubmit={addTelegram} >
                                        <input type="radio" id="private-msg" name="msg_type" value="Private Message" onChange={onValueChange} checked={selectedOption === "Private Message"} />
                                        &nbsp; <label htmlFor="private-msg">Private Message</label>
                                        &nbsp; <input type="radio" id="group-msg" name="msg_type" value="Group Message" onChange={onValueChange} checked={selectedOption === "Group Message"} />
                                        &nbsp; <label htmlFor="group-msg">Group Message</label>
                                        &nbsp; <input type="radio" id="email-msg" name="msg_type" value="Email Message" onChange={onValueChange} checked={selectedOption === "Email Message"} disabled />
                                        &nbsp; <label htmlFor="email-msg" disabled >Email Message</label><br /><br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Group {name} name</label>
                                                    <input type="text" name="groupName" className="form-control" placeholder="Eg: data.nome.fi_alarms" />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Group {name} message ID</label>
                                                    <input type="text" name="groupMsgId" className="form-control" placeholder="Eg: -1001442873928" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary">Add</button>
                                            &nbsp; &nbsp;
                                        </div>
                                    </form>
                                    <button className="close" onClick={close}>
                                        &times;
                                    </button>
                                </div>
                            )}
                        </Popup>
                    </div>
                    <TelegramItems items={items} setItems={setItems} deleteItem={deleteItem} editItem={editItem} />
                </div>
            </div>
        </>
    )
}
