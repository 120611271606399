import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import APIRegister from '../../../api/auth/APIRegister'
// notification style
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function Register(props) {
  toast.configure();

  const [scode, setSCode] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [name, setName] = useState("")
  function register(event)
  {
    event.preventDefault();
    APIRegister(scode, name, email, pwd)
    .then(results => {
      if(results.status === 403) toast.error(results.data, { autoClose: 1600 })
      else {
        props.setEmailRegister(email)
        toast.success('The verify code has been sent to your registered email !', { autoClose: 1600 })
        props.history.push(props.redirectPathOnSuccess)
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  const classes = useStyles();

  var token = localStorage.getItem('token')
  if(!token) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
         Register
        </Typography>
        <Typography variant="caption">Please enter the secret key and your email address to create your account. An email containing further instructions will be sent to the address provided.</Typography>
        
        <form className={classes.form} onSubmit={register}>
          <TextField variant="outlined" margin="normal" required fullWidth label="Secret key" autoFocus
            name="scode"
            value={scode}
            onChange={(e) => {setSCode(e.target.value)}}
          />
          <TextField variant="outlined" margin="normal" required fullWidth  label="Name" id="name" autoComplete="name"
                      name="name"
                      value={name}
                      onChange={(e) => {setName(e.target.value)}}
          />
          <TextField variant="outlined" margin="normal" required fullWidth  label="Email" id="email" autoComplete="email"
                      name="email"
                      value={email}
                      onChange={(e) => {setEmail(e.target.value)}}
          />
          <TextField variant="outlined" type='password' margin="normal" required fullWidth  label="Password" id="password" autoComplete="password"
                      name="password"
                      value={pwd}
                      onChange={(e) => {setPwd(e.target.value)}}
          />
          <Button type="submit" fullWidth  variant="contained" color="primary" className={classes.submit}  >
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
                <Link to="/identify" variant="body2">
                  {"Already created? Submit your code here"}
                </Link>
            </Grid>
            <Grid item>
              <Link to ="/" variant="body2" style={{ textDecoration: 'none' }}>
                {"Cancel create account"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
  } else {
    return(
      <Redirect to="devices"/>
    );
  }
}