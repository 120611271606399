const options = {
    options: {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
        },
        annotations: {
            yaxis: [
              {
                borderColor: '#000',
                fillColor: '#00ff00',
                label: {
                  text: 'Safe'
                }
              }
              ,
              {
                borderColor: '#000',
                fillColor: '#FEB019',
                label: {
                  text: 'Warning'
                }
              }
              ,
              {
                borderColor: '#cc0000',
                fillColor: '#cc0000',
                label: {
                  text: 'Alert'
                }
              }
            ]
        },
        colors: ['#000000'],
        noData: {
            text: undefined,
            align: 'center',
            verticalAlign: 'middle',
            style: {
                fontSize: '14px',
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [2],
        },
        title: {
            text: 'VRMS trending',
            align: 'left',
        },
        xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
                , title: {
                    text: 'Time'
                }
            }
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                title: {
                    text: "VRMS (mm/s)",
                    style: {
                        color: '#000000',
                    }
                },
                tooltip: {
                    enabled: true,
                    show: false,
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: function (val) {
                        return Math.round(val * 100) / 100;
                    },
                }
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            },
            style: {
                fontSize: '11px',
                fontFamily: undefined
            },
            y: {
                formatter: function (val) {
                    return Math.round(val * 10000) / 10000;
                }
            },
            x: {
                show: false,
                formatter: function (val) {
                    var date = new Date(val);
                    var convertedDate = date.toLocaleString('en-GB', { hour12: false })
                    return `Time : ` + convertedDate;

                }
            }
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        },
    }
}

module.exports = options