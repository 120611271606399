import React, { Component } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Header from './components/headers/Header'
import MainPages from './components/mainpages/Pages'
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
      isDisplay: true, //for navbar login
      logIn: false,
      blocking: false
    };
  }

  unblockIn(seconds) {
    this.setState({countdown: seconds});
    if(!seconds) {
      this.setState({blocking: false});
    } else {
      setTimeout(() => this.unblockIn(--seconds), 1000)
    }
  }
  
  setDevice = (device) => {
    this.setState({ device, blocking: !this.state.blocking}) 
    this.unblockIn(3);
  }
  setDisplay = () => {this.setState({ isDisplay: !this.state.isDisplay })}
  onLogin = (token, refreshToken, name, permissionLevel) => {
    localStorage.setItem("username", name);
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("permissionLevel", permissionLevel)
    this.setState({logIn: true, isDisplay: true,})
  }
  onLogOut = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissionLevel")
    localStorage.removeItem("database")
    localStorage.removeItem("dbName")
  }

  toggleBlocking = () => {
    this.setState({blocking: !this.state.blocking});
    this.unblockIn(5);
  }

  render() {
    return (
      <Router>
        <div>
          <Header onLogOut = {this.onLogOut} setDevice={this.setDevice} 
                  status={this.state.logIn} setDisplay={this.setDisplay} />
          <MainPages device={this.state.device} onLogin = {this.onLogin} isDisplay={this.state.isDisplay} onLogOut = {this.onLogOut}
                     status={this.state.logIn} blocking={this.state.blocking} toggleBlocking={this.toggleBlocking}
                     />
        </div>

      </Router>
    );
  } _
}

export default App;
