import React, { useEffect, useState } from 'react'
import APIGetTelegramAlarms from '../../../api/telegram/APIGetTelegramAlarms';
import { Edit, Delete , Send } from '@material-ui/icons';
import { Tooltip, IconButton } from "@material-ui/core/";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styles from "./TelegramSettings.module.css";
import APISendTestMsg from '../../../api/telegram/APISendTestMsg';

export default function Telegramitems(props) {

    const { items, setItems, deleteItem, editItem } = props

    useEffect(() => {
        APIGetTelegramAlarms()
            .then(res => {
                const { data } = res
                setItems(data)
            })
            .catch(err => console.log(err))
    }, [])

    const sendTestMsgTelegram = (id) => {
        APISendTestMsg(id)
        .then(res => {
            if (res.status === 200) alert('Send test message success. Please check it again in your telegram.')
        })
        .catch(err => alert('Something went wrong while sending your telegram test message. Please try again later.'))
    }

    var Item
    if (items.length > 0) Item = items.map((e, i) => {
        return (
            <div key={i} className="col-sm-6 col-lg-4" >
                <div className="card border-primary mb-5" style={{ display: 'flex' }} >
                    <div className="card-header"><b>Type</b>: {e.type === 'telegram' ? "Private Message" : "Group Message"}</div>
                    <div className="card-body text-dark">
                        <h5 className="card-title"><b>Name: </b>{e.name}</h5>
                        <p className="card-text"><b>Message ID: </b>{e.uri}</p>
                        <div className={styles.buttons}> 

                            {/* EDIT ITEM */}
                            <Tooltip title="Send test message">
                                <IconButton onClick = {() => sendTestMsgTelegram(e.id)}>
                                    <Send color="success" />
                                </IconButton>
                            </Tooltip>

                            <Popup trigger={
                                <Tooltip title="Edit alarm">
                                    <IconButton>
                                        <Edit color="primary" />
                                    </IconButton>
                                </Tooltip>
                            } modal>
                                {close => (
                                    <div className={styles.popupModal}>
                                        &nbsp;
                                        <form onSubmit={editItem} >
                                            <div className="row">
                                                <input name="id" hidden={true} value={e.id} />
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>New name</label>
                                                        <input type="text" name="groupName" className="form-control" placeholder="Eg: data.nome.fi_alarms" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>New Group message ID</label>
                                                        <input type="text" name="groupMsgId" className="form-control" placeholder="Eg: -1001442873928" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn btn-primary">Submit</button>
                                                &nbsp; &nbsp;
                                            </div>
                                        </form>
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </div>
                                )}
                            </Popup>
                            {/* DELETE ITEM */}
                            <Tooltip title="Edit alarm">
                                    <IconButton  onClick={() => deleteItem(e.id)}>
                                        <Delete color="secondary" />
                                    </IconButton>
                            </Tooltip>
                        </div>

                    </div>
                </div>
            </div>
        )
    })
    else Item = (
        <div className={styles.alarmStatus}>No alarm available!</div>
    )

    return (
        <div className="row mt-4">
            {Item}
        </div>
    )
}
