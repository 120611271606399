import constants from '../../constants.json'
import axios from 'axios'

export default function APIAddTelegramAlarm(name, type, uri) {
    const token = localStorage.getItem('token')

    let object = {
        name,
        type,
        uri
    }

    var config = {
        method: 'post', 
        headers: {
          'Authorization': `token ${token}`
        },
        url: constants.baseAddress + 'alarm',
        data: object
      };

    return axios(config)
        .then(response => response)
        .catch(error => error);
}