import React, {useState} from 'react';
import { Redirect} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// notification style
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function Password(props) {
  const classes = useStyles();
  toast.configure();

  const [pwd, setPwd] = useState("");
  const [repwd, setRePwd] = useState("");

  function onhandlePwd(event) {
    event.preventDefault();
    if (pwd === repwd) {
      props.history.push(props.redirectPathOnSuccess);
      toast.success('Create successful, please log in again')
    } else {
      toast.warning('Password not match !')
    }
  }
  var token = localStorage.getItem('token');
  if (!token) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create password
        </Typography>

        <form className={classes.form} onSubmit={onhandlePwd}>
          <TextField  variant="outlined"   margin="normal"    required  fullWidth   name="password" label="Password"
            type="password" id="password"  autoComplete="current-password"
            value={pwd}
            onChange={(e) => {setPwd(e.target.value)}}
          />
          <TextField variant="outlined"   margin="normal"  required  fullWidth label="Re-password" type="password" id="re-password" autoComplete="current-password"
            name="rePassword"
            value={repwd}
            onChange={(e) => {setRePwd(e.target.value)}}
          />
          <Button type="submit"  fullWidth variant="contained" color="primary"   className={classes.submit} >
            Create
          </Button>
        </form>
      </div>
    </Container>
  );
  } else {
    return(
      <Redirect to="/devices"/>
    );
  }
}