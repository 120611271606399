import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// notification style
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import APILogin from '../../../api/auth/APILogin'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://nome.fi/">
        Nome Oy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    marginTop: '-60px'
  },
  image: {
    backgroundImage: 'url(img/chess.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props) {
  const classes = useStyles();
  toast.configure();

  function onLogin(event) {
    event.preventDefault();
    var username = event.target['username'].value
    var password = event.target['password'].value

    APILogin(username, password)
    .then((result) => {
      if(result.data !== undefined) {
        var {token, refreshToken} = result.data
        var {name, permissionLevel} = result.data.user
        props.onLogin(token, refreshToken, name, permissionLevel)
        props.history.push(props.redirectPathOnSuccess)
        toast.success('login success', { autoClose: 1600 })
      } else {
        toast.error('login failed', { autoClose: 1600 })
      }
    })
    .catch(error => console.log(error))
    
  }

  var token = localStorage.getItem('token');
  if (!token) {
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
  
            {/* form */}
            <form className={classes.form} noValidate onSubmit={onLogin}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="username"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/recover" variant="body2">
                   {"Forgot password?"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Redirect to="/devices"/>
    )
  }
}