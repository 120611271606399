const radia = {
  // ENVELOPE
  ENVELOPE: {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: 20
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#888",
            fontSize: "13px"
          },
          value: {
            offsetY: 0,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              const max = 3;
              return (val/100*max).toFixed(3);
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -21
      }
    },
    fill: {
      type: 'gradient',
      colors: [function({value}) {
        if(value > 80) {
            return '#ff0000'
        } else if (value >= 20 && value <= 80) {
            return '#ffff00'
        } else {
            return '#0000ff'
        }
      }]
    },
    labels: ['ENVELOPE'],
  },
  // VRMS
  VRMS: {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: 20
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#888",
            fontSize: "13px"
          },
          value: {
            offsetY: 0,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              const max = 8;
              return (val/100*max).toFixed(3);
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -21
      }
    },
    fill: {
      type: 'gradient',
      colors: [function({value}) {
        if(value > 80) {
            return '#ff0000'
        } else if (value >= 20 && value <= 80) {
            return '#ffff00'
        } else {
            return '#0000ff'
        }
      }]
    },
    labels: ['VRMS'],
  },
  // SIGNAL GPEAK
  SIGNAL_GPEAK: {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: 20
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#888",
            fontSize: "13px"
          },
          value: {
            offsetY: 0,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              const max = 5;
              return (val/100*max).toFixed(3);
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -21
      }
    },
    fill: {
      type: 'gradient',
      colors: [function({value}) {
        if(value > 80) {
            return '#ff0000'
        } else if (value >= 20 && value <= 80) {
            return '#ffff00'
        } else {
            return '#0000ff'
        }
      }]
    },
    labels: ['SIGNAL GPEAK'],
  },
  // GPEAK
  GPEAK: {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: 20
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#888",
            fontSize: "13px"
          },
          value: {
            offsetY: 0,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              const max = 5;
              return (val/100*max).toFixed(3);
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -21
      }
    },
    fill: {
      type: 'gradient',
      colors: [function({value}) {
        if(value > 80) {
            return '#ff0000'
        } else if (value >= 20 && value <= 80) {
            return '#ffff00'
        } else {
            return '#00ff00'
        }
      }]
    },
    labels: ['GPEAK'],
  },
  // BATTERY
  BATTERY: {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: 20
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#888",
            fontSize: "13px"
          },
          value: {
            offsetY: 0,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -21
      }
    },
    fill: {
      type: 'gradient',
      colors: [function({value}) {
        if(value >= 80) {
            return '#00ff00'
        } else if (value >= 20 && value < 80) {
            return '#ffff00'
        } else {
            return '#ff0000'
        }
      }]
    },
    labels: ['BATTERY']
  },
  // TEMPERATURE
  TEMPERATURE: {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: 20
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#888",
            fontSize: "13px"
          },
          value: {
            offsetY: 0,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -21
      }
    },
    fill: {
      type: 'gradient',
      colors: [function({value}) {
        if(value >= 80) {
            return '#ff0000'
        } else if (value >= 20 && value < 80) {
            return '#ffff00'
        } else {
            return '#0000ff'
        }
      }]
    },
    labels: ['TEMPERATURE']
  }
}

module.exports = radia;