import constants from '../constants.json'
import axios from 'axios'
export default function APIGetAlarms(token, device) {
  
  var data = {};

  const databaseId  = localStorage.getItem('database')
  const permissionLevel = localStorage.getItem('permissionLevel')
  var adminObj = {
    "targetId": databaseId
  }

  data = permissionLevel === 'admin' ? adminObj : {}
  
  var config = {
    method: 'get', 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'locations/alert/' +device,
    params: data
  };
  
  return axios(config)
  .then(response => response.data)
  .catch(function(error) {
    if (!error.status) {
      console.log('get alarms error')
    }});
}

