import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
    <h1 style={{marginLeft: '20%'}}>404 - Not Found! <Link to="/">
      Click here to go back to home
    </Link></h1>
  </div>
);

export default NotFound;
