import React from 'react'
import configData from './config/configData.json'
import TimeSignal from './TimeSignal'
import FFTSignal from './FFTSignal'
import TrendingBtn from './TrendingBtn'
import './graph.css'
import TimeSignalTrending from './TimeSignalTrending'
import TrendIndicators from './TrendIndicators'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import toTime from './function/toTime'

export default class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: configData
      , isReady: false
      , timestamp: 0
      , rate: 0
      , fftFreq: 0
      , fftFreqMMS: 0
      , fftMag: 0
      , _show0to1Hz: 0
      , drag: false
      , annotation: null
    }
  }

  componentDidUpdate(prevProps) { if (this.props.device !== prevProps.device) { this.setState({ timestamp: 0 }) } }
  setTimeStamp = (timestamp) => { this.setState({ timestamp }) }
  onReceiveRate = (rate) => { this.setState({ rate }) }

  convertGtoMMs = (F,G) => {
    var result = G.map(function(eG, i) { 
      if (F[i] > 1) return ( (eG / (2* Math.PI * F[i])) * 9800 ) 
      else return 0;
    })
    // result = result.filter(function( element ) {
    //   return element !== undefined;
    // });
    return result;
  } 

  show0to1Hz = (F,G) => {
    var result = G.map(function(eG, i) { 
      if (F[i] <= 1) return ( (eG / (2* Math.PI * F[i])) * 9800 ) 
    })
    result = result.filter(function( element ) {
    return element !== undefined;
    });
    return result;
  } 

  // need to remove the first useless data point
  onReceiveFFT = (fft) => {
    var fftFreq = fft.frequency.slice(1, fft.index)
    var fftMag = fft.magnitude.slice(1, fft.index)
    var fftFreqMMS = this.convertGtoMMs(fftFreq, fftMag)
    var _show0to1Hz = this.show0to1Hz(fftFreq, fftMag)
    this.setState({ 
      fftFreq, 
      fftMag,
      fftFreqMMS,
      _show0to1Hz
    })
  }

  onReceiveAnnotation = (point) => { this.setState({ annotation: point })}

  onDrag = () => { this.setState({ drag: !this.state.drag }) }

  render() {
    return (
      <>
        <div className="row">
          <div className={this.state.drag ? "col-md-12 mb-2" : "col-md-6"}>
            <Card className="mb-2" style={{height:'350px'}}>
              <CardContent>
                <div style={{height:'340px'}}>
                  <TimeSignalTrending port={this.props.port} id={this.props.device} setTimeStamp={this.setTimeStamp} 
                                      timestamp={this.state.timestamp} update = {this.props.updateTS} onDrag = {this.state.drag}/>
                    {/* update & drag button */}
                    <TrendingBtn name = {['VRMS', 'Signal Gpeak', 'Envelope']} onUpdateTS = {this.props.onUpdateTS} onDrag = {this.onDrag}
                                                                              port={this.props.port} id={this.props.device}/>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className={this.state.drag ? "col-md-12 mb-3" : "col-md-6 mb-3"}>
            <Card style={{height:'350px'}}>
              <CardContent>
                <div style={{height:'340px'}} >
                  <TrendIndicators port={this.props.port} id={this.props.device} timestamp={this.state.timestamp} 
                                    onDrag = {this.state.drag} update = {this.props.updateTS} toggleBlocking={this.props.toggleBlocking}/>
                  {/* update & drag button */}
                  <TrendingBtn name = {['Gpeak', 'Battery', 'Temperature']} onUpdateTS = {this.props.onUpdateTS} onDrag = {this.onDrag}
                                                                          port={this.props.port} id={this.props.device}/>
                </div>
              </CardContent>
            </Card>
          </div>
          {/* info line */}
          <div className="col-md-3 mb-3"> <span>Device: <b>{this.props.device}</b></span></div>
          <div className="col-md-4 mb-3">  <span>Timestamp: <b>{this.state.timestamp === 0 ? '' : toTime(this.state.timestamp)}</b></span></div>
          <div className="col-md-3 mb-3"> <span>Rate: <b style={{ textAlign: 'center' }}>{this.state.rate}</b></span></div>
          <div className="col-md-2 mb-3"> <span>Length: <b>3s</b></span></div>
          
          <div className="col-md-6 mb-2">
            <Card style={{height:'250px'}}>
              <CardContent>
                <div style={{height:'250px'}}>
                  <TimeSignal port={this.props.port} id={this.props.device} timestamp={this.state.timestamp} onReceiveFFT={this.onReceiveFFT} onReceiveRate={this.onReceiveRate}  />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="col-md-6">
            <Card style={{height:'250px'}}>
              <CardContent>
                <div style={{height:'250px'}}>
                  <FFTSignal id={this.props.device} onReceiveAnnotation={this.onReceiveAnnotation} annotation={this.state.annotation} 
                      fftFreq={this.state.fftFreq} fftMag={this.state.fftMag} fftFreqMMS = {this.state.fftFreqMMS} _show0to1Hz = {this.state._show0to1Hz}  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    )
  }
}
