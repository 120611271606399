import { Tab, Row, Col, Nav } from 'react-bootstrap'
import './AccountStyle.css'
import { AccountCircle, Lock, Telegram } from '@material-ui/icons';

import Passwordsettings from './PasswordSettings';
import Accountsettings from './AccountSettings';
import Telegramsettings from './TelegramSettings';

export default function Account(props) {

    return (
        <div className="container-fluid" style={props.isDisplay ? { marginLeft: "10rem", width: "88%" } : { marginLeft: "2rem", width: "88%" }}>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column nav-tab" >
                            <Nav.Item>
                                <Nav.Link eventKey="first"> <AccountCircle /> &nbsp; Account </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"> <Telegram /> &nbsp; Telegram </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third"> <Lock /> &nbsp; Password </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <section class="py-5 my-5">
                                    <div class="container">
                                        <div class="bg-white shadow rounded-lg d-block d-sm-flex">
                                            <Accountsettings/>
                                        </div>
                                    </div>
                                </section>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <section class="py-5 my-5">
                                    <div class="container">
                                        <div class="bg-white shadow rounded-lg d-block d-sm-flex">
                                            <Telegramsettings/>
                                        </div>
                                    </div>
                                </section>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <section class="py-5 my-5">
                                    <div class="container">
                                        <div class="bg-white shadow rounded-lg d-block d-sm-flex">
                                            <Passwordsettings />
                                        </div>
                                    </div>
                                </section>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
    )
}
