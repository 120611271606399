import constants from '../../constants.json'
import axios from 'axios'
export default function APIEditTelegramAlarm(id, name, uri) {
  
  const token  = localStorage.getItem('token')
  var data = {
    name,
    uri
  }

  var config = {
    method: 'patch', 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'alarm/'+id,
    data
  };
  
  return axios(config)
  .then((res) => res)
  .catch((error) => {
    console.error(error)
  })
}

