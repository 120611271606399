import constants from '../constants.json'
import axios from 'axios'

export default function APIGetLocations(token) {

  var data = {}

  const databaseId  = localStorage.getItem('database')
  const permissionLevel = localStorage.getItem('permissionLevel')
  var adminObj = {
    "targetId": databaseId
  }

  data = permissionLevel === 'admin' ? adminObj : {}

  var config = {
    method: 'get', // should change to post, 
    headers: {
      'Authorization': `token ${token}`
    },
    url: constants.baseAddress + 'locations',
    params: data
  };

  return axios(config)
  .then((res) => res)
  .catch((error) => {
    console.error(error)
  })
}

