import React, { Component } from "react";
import Chart from "react-apexcharts";
import options from '../config/trendOption'
import valueToPercent from '../function/valueToPercent'
import findColor from '../function/findColor'
class SignalGpeak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesRadial: [0],
      option: options.SIGNAL_GPEAK
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      console.log(this.props)
      this.setState({
        seriesRadial: [valueToPercent(this.props.data, 5)],
        option: {
          ...this.state.option,
          fill: {
            type: 'gradient',
            colors: [findColor(this.props.warning, this.props.alert, this.props.data)]
          },
        }
      });
    }
  }
  render() {
    return (
      <div className="col-sm">
        <Chart
          options={this.state.option}
          series={this.state.seriesRadial}
          type="radialBar"
          width="100"
          height="150"
        />
      </div>
    );
  }
}

export default SignalGpeak;