import constants from '../../src/constants.json'
import axios from 'axios'
export default function APIRefreshToken(token) {
    return axios.post(constants.baseAddress+'login', {
        "token" : token
    })
    .then(response => response.data)
    .catch(error => 
        error.response.status
    );
    
}