import constants from '../../constants.json'
import axios from 'axios'
export default function APIRegister(key, name, email, password ) {
    return axios.post(constants.baseAddress2+'/user', {
        "key": key,
        "name": name,
        "password" : password,
        "email" : email
    })
  .then(response => response)
  .catch(error => error.response)
}