import React, { Component } from "react";
import Chart from "react-apexcharts";
import options from '../config/trendOption'
class Battery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesRadial: [0],
      option: options.BATTERY
    };
  }

  findColor = (value) => {
    if (this.props.warning == null || this.props.alert == null) return '#00ff00'
    else if (value <= this.props.alert) return '#ff0000'
    else if (value > this.props.alert && value < this.props.warning) return '#ffff00'
    else return '#00ff00'
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState(prevState => ({
        seriesRadial: [this.props.data],
        option: {
          ...this.state.option,
          fill: {
            type: 'gradient',
            colors: [this.findColor(this.props.data)]
          },
        }
        
      }));
    }
  }
  render() {
    return (
      <div className="col-sm">
        <Chart
          options={this.state.option}
          series={this.state.seriesRadial}
          type="radialBar"
          width="100"
          height="150"
        />
      </div>
    );
  }
}

export default Battery;